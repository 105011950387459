import React, { useState, useEffect, useRef, useCallback } from 'react';
import CategorySelector from './components/CategorySelector';
import SubcategoryScroll from './components/SubcategoryScroll';
import DishList from './components/DishList';
import DishDrawer from './components/DishDrawer';
// import SessionPopup from './components/SessionPopup';

function App() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [dishesBySubcategory, setDishesBySubcategory] = useState({});
  const [selectedDish, setSelectedDish] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [showSubcategory, setShowSubcategory] = useState(false);

  const [targetSubcategory, setTargetSubcategory] = useState(null);
  const [language, setLanguage] = useState('ro');
  const [firstLoad, setFirstLoad] = useState(true);

  const subcategoryRefs = useRef({});
  const prevScrollY = useRef(0);


  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await fetch('https://admin.perfettocucinaitaliana.ro/api/categories');
        const data = await response.json();
        setCategories(data.categories);
        if (data.categories.length > 0) {
          setSelectedCategory(data.categories[0].id);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    }
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      async function fetchSubcategories() {
        try {
          const response = await fetch(`https://admin.perfettocucinaitaliana.ro/api/subcategories?categoryId=${selectedCategory}`);
          const data = await response.json();
          setSubcategories(data.subcategories);

          const fetchedDishesBySubcategory = {};
          for (const subcategory of data.subcategories) {
            const response = await fetch(`https://admin.perfettocucinaitaliana.ro/api/front-documents?subcategoryId=${subcategory.id}`);
            const dishData = await response.json();
            fetchedDishesBySubcategory[subcategory.enName] = dishData.documents;
          }
          setDishesBySubcategory(fetchedDishesBySubcategory);

          if (data.subcategories.length > 0) {
            window.scrollTo({
              top: 1,
              behavior: 'smooth'
            });
            setSelectedSubcategory(data.subcategories[0].enName);
            setTargetSubcategory(data.subcategories[0].enName);
          }
        } catch (error) {
          console.error('Error fetching subcategories or dishes:', error);
        }
      }
      fetchSubcategories();
    }
  }, [selectedCategory]);

  const handleSelectDish = (dish) => {
    setSelectedDish(dish);
  };

  const handleSelectSubcategory = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
    setTargetSubcategory(subcategoryId);
    console.log('ID :' + subcategoryId)
    const element = document.getElementById(subcategoryId);
    if (element) {
      const offset = -70; // Adjust this value based on your header height
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });


    }
  };

  const handleScroll = useCallback(() => {
    const headerHeight = window.innerHeight * 0.5; // 50vh to pixels
    const scrollPosition = window.scrollY + headerHeight;

    let currentSubcategory = null;
    Object.keys(subcategoryRefs.current).forEach((key) => {
      const ref = subcategoryRefs.current[key];
      if (ref && ref.offsetTop <= scrollPosition) {
        currentSubcategory = key;
      }
    });

    if (targetSubcategory === currentSubcategory) {
      setTargetSubcategory(null);
    }

    if (!targetSubcategory) {
      setSelectedSubcategory(currentSubcategory);
    }

    const currentScrollY = window.scrollY;

    if (currentScrollY < prevScrollY.current - 10) {
      setShowSubcategory(false);
    } else if (currentScrollY > prevScrollY.current + 10) {
      setShowSubcategory(true);
    }

    prevScrollY.current = currentScrollY;
  }, [targetSubcategory]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleSelectCategory = (categoryId) => {
    setDishesBySubcategory(null);
    setSelectedCategory(categoryId);

  };

  const toggleLanguage = () => {
    setLanguage(lang => lang === 'en' ? 'ro' : 'en');
  };

  if (firstLoad) {
    setTimeout(() => {
      setFirstLoad(false);
    }, 2000);
    return (
      <div className="relative flex flex-col items-center justify-center w-screen h-svh bg-perfetto-lut">
        <h2 className="text-4xl text-center font-perfetto fade-in">Perfetto</h2>
        <section className="flex flex-row w-2/3 p-6">
          <div className="w-1/3 border-t-2 border-green-600"></div>
          <div className="w-1/3 border-t-2 border-white"></div>
          <div className="w-1/3 border-t-2 border-red-600"></div>
        </section>
        <h2 className="text-2xl text-center fade-in font-perfetto">Cucina Italiana</h2>
        <img src='/img/Left-corner.png' alt='Lemon Left Perfetto' className='absolute top-0 left-0 w-2/3'></img>
        <img src='/img/Right-corner.png' alt='Lemon Left Perfetto' className='absolute bottom-0 right-0 w-2/3'></img>
      </div >
    )
  }

  return (
    <div className="App">
      <div className={`sticky top-0 z-20 transition-all duration-300 ease-linear ${showSubcategory ? '' : 'translate-y-0'}`}>
        <CategorySelector
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={handleSelectCategory}
          onToggleLanguage={toggleLanguage}
          language={language}
        />

      </div>

      <div className={`sticky top-[44px] z-20 transition-all duration-300 ease-linear ${showSubcategory ? ' !top-[0px]' : ''}`}>
        <SubcategoryScroll
          subcategories={subcategories}
          selectedSubcategory={selectedSubcategory}
          onSelectSubcategory={handleSelectSubcategory}
          dishesBySubcategory={dishesBySubcategory}
          language={language}
        />
        <section className="flex flex-row w-full shadow-sm">
          <div className="w-1/3 border-t-2 border-green-600"></div>
          <div className="w-1/3 border-t-2 border-white"></div>
          <div className="w-1/3 border-t-2 border-red-600"></div>
        </section>
      </div>
      <DishList
        dishesBySubcategory={dishesBySubcategory}
        onSelectDish={handleSelectDish}
        subcategoryRefs={subcategoryRefs}
        language={language}
      />
      {
        selectedDish && (
          <DishDrawer
            dish={selectedDish}
            language={language}
            onClose={() => setSelectedDish(null)}
          />
        )
      }
      {/* <SessionPopup /> */}
    </div >
  );
}

export default App;
